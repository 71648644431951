import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
  const closeSidebar = () => {
    document.querySelector('.sidebar').style.left = '-250px';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.sidebar') && !event.target.closest('.navbar-toggle')) {
        closeSidebar();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-close" onClick={closeSidebar}>
        <i className="fas fa-times"></i>
      </div>
      <ul>
        <li><Link to="/gallery" onClick={closeSidebar}>Gallery</Link></li>
        <li><Link to="/category/children" onClick={closeSidebar}>Children</Link></li>
        <li><Link to="/category/teenagers" onClick={closeSidebar}>Teenagers</Link></li>
        <li><Link to="/category/adults" onClick={closeSidebar}>Adults</Link></li>
        <li><Link to="/category/couples" onClick={closeSidebar}>Couples</Link></li>
        <li><Link to="/category/families" onClick={closeSidebar}>Families</Link></li>
        <li><Link to="/category/seniors" onClick={closeSidebar}>Seniors</Link></li>
        <li><Link to="/category/business" onClick={closeSidebar}>Business & Corporate</Link></li>
        <li><Link to="/category/fashion" onClick={closeSidebar}>Beauty & Fashion</Link></li>
        <li><Link to="/category/health" onClick={closeSidebar}>Health & Fitness</Link></li>
        <li><Link to="/category/concepts" onClick={closeSidebar}>Concepts & Ideas</Link></li>
        <li><Link to="/category/education" onClick={closeSidebar}>Education & Learning</Link></li>
        <li><Link to="/category/festivals" onClick={closeSidebar}>Festivals & Occasions</Link></li>
        <li><Link to="/category/food" onClick={closeSidebar}>Food & Drink</Link></li>
      </ul>
    </div>
  );
}

export default Sidebar;
