import React from 'react'

const Contact = () => {
  return (
    <div>
      <h2>Contact us</h2>
    </div>
  )
}

export default Contact
