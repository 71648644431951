import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const toggleSidebar = () => {
    document.querySelector('.sidebar').style.left = '0';
  };

  return (
    <nav className="navbar">
      <div className="navbar-toggle" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
        <span> Browse Categories</span>
      </div>
      <div className="navbar-brand">
        <Link to="/">Imagewale</Link>
      </div>
      <div className="navbar-links">
        <Link to="/contact" className="btn btn-dark">Contact Us</Link>
      </div>
    </nav>
  );
}

export default Navbar;
