
export const galleryData = [

  {
    category: 'child',
    href: require('./Photos/child/c1.jpg'), 
    imgSrc: require('./Photos/child/c1.jpg')
  },
  {
    category: 'child',
    href: require('./Photos/child/c2.jpg'),
    imgSrc: require('./Photos/child/c2.jpg')
  }, 


  {
    category: 'girl',
    href: require('./Photos/girl/g1.jpg'),
    imgSrc: require('./Photos/girl/g1.jpg')
  },
  {
    category: 'child',
    href: require('./Photos/child/c4.jpg'), 
    imgSrc: require('./Photos/child/c4.jpg')
  },
  {
    category: 'girl',
    href: require('./Photos/girl/g2.jpg'),
    imgSrc: require('./Photos/girl/g2.jpg')
  },
 
  {
    category: 'child',
    href: require('./Photos/child/c3.jpg'),
    imgSrc: require('./Photos/child/c3.jpg')
  },
  {
    category: 'girl',
    href: require('./Photos/girl/g3.jpg'),
    imgSrc: require('./Photos/girl/g3.jpg')
  },
  {
    category: 'girl',
    href: require('./Photos/girl/g4.jpg'),
    imgSrc: require('./Photos/girl/g4.jpg')
  },
  
];
