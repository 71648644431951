import React, { useEffect, useCallback } from 'react';
import { galleryData } from '../Data';
import $ from 'jquery';
import 'magnific-popup/dist/magnific-popup.css';
import Isotope from 'isotope-layout';
import 'magnific-popup';
import './Gallery.css'; // Make sure this file has the necessary CSS

function Gallery() {
  const handleDownload = useCallback((e) => {
    e.preventDefault();
    const imgSrc = $(e.currentTarget).data('src');

    // Ensure the download link is created and clicked only once
    const link = document.createElement('a');
    link.href = imgSrc;
    link.download = imgSrc.split('/').pop(); // Extracts the filename

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  useEffect(() => {
    const portfolioItems = document.getElementById('portfolio-items');

    // Clear existing items to avoid duplication
    portfolioItems.innerHTML = '';

    // Insert gallery items dynamically
    galleryData.forEach(item => {
      const itemHtml = `
        <div class="item ${item.category} col-lg-3 col-md-4 col-6 col-sm">
          <a href="${item.href}" class="fancylight popup-btn" data-fancybox-group="light">
            <img class="img-fluid" src="${item.imgSrc}" alt="">
          </a>
          <button class="download-link btn btn-primary mt-2" data-src="${item.imgSrc}">
             <i class="fas fa-download"></i>
          </button>
        </div>
      `;
      portfolioItems.innerHTML += itemHtml;
    });

    // Initialize Isotope
    const $grid = new Isotope('.portfolio-item', {
      itemSelector: '.item',
      layoutMode: 'fitRows'
    });

    // Filter items on button click
    $('.portfolio-menu ul li').click(function () {
      $('.portfolio-menu ul li').removeClass('active');
      $(this).addClass('active');

      const selector = $(this).attr('data-filter');
      $grid.arrange({ filter: selector });

      // Clear the search bar
      $('#search-bar').val('');

      // Show all items when the search bar is cleared
      $('.item').show();
      $('#not-found').hide();
      $grid.layout(); // Ensure items are positioned correctly

      return false;
    });

    // Initialize Magnific Popup
    $('.popup-btn').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true
      }
    });

    // Bind download functionality
    $(document).on('click', '.download-link', handleDownload);

    // Search functionality
    $('#search-bar').on('keyup', function () {
      const value = $(this).val().toLowerCase();
      let found = false;

      $('.item').each(function () {
        const category = $(this).attr('class').split(' ').filter(c => c !== 'item' && c !== 'col-lg-3' && c !== 'col-md-4' && c !== 'col-6' && c !== 'col-sm')[0];
        if (category.includes(value)) {
          $(this).show();
          found = true;
        } else {
          $(this).hide();
        }
      });

      $('#not-found').toggle(!found);
      $grid.layout(); // Ensure items are positioned correctly
    });

    // Cleanup function to remove event bindings
    return () => {
      $(document).off('click', '.download-link', handleDownload);
    };
  }, [handleDownload]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center my-2">
          <div className="input-group">
            <input type="text" id="search-bar" placeholder="Search..." className="form-control" />
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="fas fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio-menu mt-2 mb-4">
        <ul>
          <li className="btn btn-outline-dark active" data-filter="*">All</li>
          <li className="btn btn-outline-dark" data-filter=".child">Children</li>
          <li className="btn btn-outline-dark" data-filter=".girl">Girls</li>
        </ul>
      </div>
      <div id="not-found" className="text-center" style={{ display: 'none' }}>No items found</div>
      <div id="portfolio-items" className="portfolio-item row"></div>
    </div>
  );
}

export default Gallery;
