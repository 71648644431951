import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Gallery from './components/Gallery';
// import Home from './components/Home'; 
import Contact from './components/Contact'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Sidebar />
        <main>
          <Routes>
            <Route path="/" element={<Gallery />} />
            {/* <Route path="/" element={<Footer/>} /> */}
            {/* <Route path="/gallery" element={<Gallery/>} /> */}
            {/* <Route path="/category/:categoryName" element={<CategoryPage />} /> */}

            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>

      <Footer/>

    </Router>
  );
}

export default App;
