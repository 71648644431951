// src/components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css'; 

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3 contact-info">
            <p><i className="fas fa-envelope"></i> info@imagesbazaar.com</p>
            <p><i className="fas fa-phone-alt"></i> All India Toll Free: 1800-11-6899</p>
            <p><i className="fab fa-whatsapp"></i> Whatsapp Us: +919911136666</p>
            <div className="social-icons">
              <a href="#"><i className="fab fa-facebook-f"></i></a>
              <a href="#"><i className="fab fa-linkedin-in"></i></a>
              <a href="#"><i className="fab fa-twitter"></i></a>
            </div>
          </div>
          <div className="col-md-3 company-info">
            <h5>Company Info</h5>
            <ul className="list-unstyled">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/">About Us</Link></li>
              {/* <li><Link to="/testimonials">Testimonials</Link></li> */}
              <li><Link to="/">Testimonials</Link></li>
              <li><Link to="/">Image Research</Link></li>
            </ul>
          </div>
          <div className="col-md-3 learn-more">
            <h5>Learn More</h5>
            <ul className="list-unstyled">
              <li><Link to="/">Pricing</Link></li>
              <li><Link to="/">Licensing</Link></li>
              <li><Link to="/">Terms of Use</Link></li>
              <li><Link to="/">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="col-md-3 need-help">
            <h5>Need Help?</h5>
            <ul className="list-unstyled">
              <li><Link to="/">Contact Us</Link></li>
              <li><Link to="/">Search Tips</Link></li>
              <li><Link to="/">FAQ</Link></li>
              <li><Link to="/">Technical</Link></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 copyright">
            <p>© <Link to="https://imagewale.com" target="_blank" rel="noopener noreferrer">imagewale.com</Link>. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
